<template>
  <div>
    <b-card v-if="plan.id">
      <div class="row">
        <div class="col-lg-8">
          <h3>
            {{ plan.label }}
            <br />
            <small>
              Mise à jour le
              {{ $dayjs(plan.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-4 text-right"></div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <!-- <plan-card :plan="plan" /> -->
        </b-tab>
        <b-tab title="Données">
          <pre>{{ plan }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <pre>{{ plan }}</pre>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'

const orderCouponFields = '*'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
      plan: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(
        `/subscriptions/plans/${this.$route.params.id}/`,
        {
          headers: {
            'X-Fields': orderCouponFields,
          },
        }
      )
      this.plan = resp.data
    },
  },
}
</script>
